import classnames from 'classnames';
import React, { useCallback } from 'react';
import './index.css';

interface IButtonV2 {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  clear?: boolean;
  primary?: boolean;
  secondary?: boolean;
  selected?: boolean;
  disabled?: boolean;
  inline?: boolean;
  gray?: boolean;
  id?: string | null;
}

const ButtonV2: React.FC<IButtonV2> = ({
  onClick,
  children,
  className,
  clear,
  primary,
  secondary,
  selected,
  disabled,
  inline,
  gray,
  id = null,
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick?.();
    }
  };
  return (
    <button
      id={id}
      className={classnames(
        'buttonV2',
        {
          'buttonV2-clear': clear,
          'buttonV2-primary': primary,
          'buttonV2-secondary': secondary,
          'buttonV2-selected': selected,
          'buttonV2-disabled': disabled,
          'buttonV2-inline': inline,
          'buttonV2-gray': gray,
        },
        className
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default ButtonV2;
