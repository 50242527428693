import { GoogleOAuthProvider } from '@react-oauth/google';
import { Logo } from 'components/common/Logo';
import FollowModal from 'components/FollowModal/FollowModal';
import Cookies from 'js-cookie';
import platform from 'platform';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';

import Modal18 from './Modal18';
import useDevelopment from '../../hooks/useDevelopment';
import {
  sendUserInfoRequest,
  sendUserInfoCreate,
  getUserFeatures,
} from '../../packets/user/UserInfoRequestPacket';
import { updateFeatures } from '../../redux/featuresSlice';
import { AppleLoginButton } from '../auth/AppleLoginButton';
import DiscordLoginButton from '../auth/DiscordLoginButton';
import { GoogleLoginButton } from '../auth/GoogleLoginButton';
import { LoginButton } from '../auth/LoginButton';
import { Faq } from '../faq';
import Feature from '../feature';
import Footer from '../Footer2';
import CookiesModal from '../modals/CookieModal';

const General = ({ showFull }: { showFull?: boolean }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { isAppleLoginVisible } = useDevelopment();
  const [captchaToken, setCaptchaToken] = useState<string | null>(
    Cookies.get('captcha-dev')
  );

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    const keitaroSubId = searchParams.get('sub');
    if (!Cookies.get('keitaroSubId') && keitaroSubId) {
      Cookies.set('keitaroSubId', keitaroSubId);
    }
  }, []);

  const [authorized, setAuthorized] = useState(
    Cookies.get('token') !== undefined
  );
  // const [is18ModalOpen, setIs18ModalOpen] = useState(true);
  const [newDesignAccess, setnewDesignAccess] = useState(false);

  const previousPath = localStorage.getItem('previousPath');

  // const handleCloseModal18 = () => {
  //   localStorage.setItem('Modal18Agree', 'true');
  //   setIs18ModalOpen(false);
  // };

  const newDesignAccessCheck = (experimentId) => {
    // if (experimentId !== null) {
    //   const access = Math.abs(experimentId) % 100 < 50;
    //   return access;
    // }

    return true;
  };

  const checkAuthorizationButton = async () => {
    if (Cookies.get('token') !== undefined) {
      await sendUserInfoRequest()
        .then((response) => {
          const isNewEdit = newDesignAccessCheck(response.data.experimentId);

          sendUserInfoCreate({
            device: platform.os.toString(),
            design: isNewEdit ? 'new' : 'old',
            browser: platform.name,
            browser_lang: navigator.language,
            language: i18n.language.split('-')[0],
            screen_resolution: `${window.screen.width}x${window.screen.height}`,
          });

          navigate(isNewEdit ? '/new-edit' : '/edit');
        })
        .catch((_e) => {
          setAuthorized(false);
        });
    } else {
      setAuthorized(false);
    }
  };

  useLayoutEffect(() => {
    const redirectUser = () => {
      // const randomValue = Math.random();

      if (location.pathname === '/undress-ai') {
        window.location.replace('/undress-ai/');
      }

      // if (
      //   (randomValue < 0.2 &&
      //     !Cookies.get('token') &&
      //     window.location.pathname === '/') ||
      //   Cookies.get('newDesignVisited') === 'true'
      // ) {
      //   navigate('/new-design');
      // }
    };

    // Делаем переход с небольшой задержкой, чтобы дать контенту страницы шанс обновиться
    const timeoutId = setTimeout(() => {
      redirectUser();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [navigate, location.pathname]);

  const isAuthorized = async () => {
    try {
      await sendUserInfoRequest();
      return true;
    } catch (_e) {
      return false;
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {showFull ? t(`showfull_general_title`) : t('general_title')}
        </title>
        <meta
          name="description"
          content={
            showFull
              ? t('showfull_general_description')
              : t('general_description')
          }
        />
      </Helmet>
      {/* {is18ModalOpen && (
        <Modal18
          isOpen={is18ModalOpen}
          onClose={handleCloseModal18}
        />
      )} */}
      <div className="px-[16px] py-[12px] mmd:px-[64px] mmd:py-[32px] llg:flex llg:flex-col llg:items-center">
        <div className="flex flex-col llg:max-w-[1898px]">
          <div className="flex flex-col mmd:flex-row mmd:gap-[30px] mmd:mb-[128px]">
            <Logo className="mmd:hidden mb-[20px]" />
            <div className="flex flex-col gap-[32px] mmd:w-full mmd:max-w-[1170px]">
              <div className="relative flex h-[calc(100vh-450px)] shrink-0 items-end justify-center self-stretch rounded-[20px] bg-[#161616] mmd:h-[calc(100vh-60px)]">
                <img
                  src="/playground_assets/banner3.webp"
                  alt="Nudified girl"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '20px',
                  }}
                />
              </div>
              <div className="flex flex-col gap-9 text-[22px] mmd:hidden">
                <p>{showFull ? t('main_long.title') : t('main.title')}</p>
                <div className="flex flex-col gap-[30px] mmd:hidden">
                  {authorized ? (
                    <button
                      className="flex justify-center rounded-full bg-orange px-2 py-5 text-[24px] font-medium transition-all hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800]"
                      onClick={async () => {
                        const auth = await isAuthorized();
                        if (auth) navigate('/new-edit');
                        setAuthorized(auth);
                      }}
                    >
                      {t('launch')}
                    </button>
                  ) : (
                    <>
                      {/* {isIpTrial && !Cookies.get('trial_expired') && (
                      <IpLoginButton captchaToken={captchaToken} />
                    )} */}
                      <div className="flex flex-col w-full gap-1">
                        <p className="font-normal text-[#C8C7CE] text-lg self-start">
                          {t('login_with')}
                        </p>
                        <div className="flex gap-4">
                          <GoogleOAuthProvider clientId="690022601515-rtvim3jb2h976i2us0gf5o9imh2kemrc.apps.googleusercontent.com">
                            <GoogleLoginButton
                              onSuccess={() => {
                                checkAuthorizationButton();
                              }}
                              onFail={(data) => {
                                if (
                                  data?.title?.indexOf(
                                    'CaptchaToken is invalid'
                                  ) !== -1
                                ) {
                                  alert(
                                    '1. Captcha expired! Please solve the Captcha again'
                                  );
                                  setCaptchaToken(null);
                                } else {
                                  try {
                                    alert(
                                      'Unknown error: ' + typeof data ===
                                        'object'
                                        ? JSON.stringify(data)
                                        : data
                                    );
                                  } catch (e) {
                                    console.log('error', e);
                                  }
                                }
                              }}
                              captchaToken={captchaToken}
                            />
                          </GoogleOAuthProvider>
                          {isAppleLoginVisible && (
                            <AppleLoginButton
                              onSuccess={() => {
                                checkAuthorizationButton();
                              }}
                              onFail={(data) => {
                                console.log(data);
                                if (
                                  'error' in data &&
                                  data.error === 'popup_closed_by_user'
                                )
                                  return;

                                if (
                                  data?.title?.indexOf(
                                    'CaptchaToken is invalid'
                                  ) !== -1
                                ) {
                                  alert(
                                    '2. Captcha expired! Please solve the Captcha again'
                                  );
                                  setCaptchaToken(null);
                                } else {
                                  try {
                                    alert(
                                      'Unknown error: ' + typeof data ===
                                        'object'
                                        ? JSON.stringify(data)
                                        : data
                                    );
                                  } catch (e) {
                                    console.log('error', e);
                                  }
                                }
                              }}
                              captchaToken={captchaToken}
                            />
                          )}
                          <DiscordLoginButton captchaToken={captchaToken} />
                          <LoginButton
                            captchaToken={captchaToken}
                            setCaptchaToken={setCaptchaToken}
                            checkAuthorizationButton={checkAuthorizationButton}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <p className={`font-normal text-[#C8C7CE] text-lg`}>
                  {showFull ? t('main_long.part1') : t('main.part1')}
                </p>
                <p className={`font-normal text-[#C8C7CE] text-lg`}>
                  {showFull ? t('main_long.part2') : t('main.part2')}
                </p>
              </div>
            </div>
            <div className="hidden flex-col justify-between gap-[60px] mmd:block mmd:flex-grow">
              <div className="hidden flex-col gap-[24px] mmd:flex mb-[16px] mmd:w-full items-stretch">
                <Logo className="mmd:mb-[20px]" />
                <div className="flex w-[420px] flex-col gap-3.5 text-[22px]">
                  <h1>{showFull ? t('main_long.title') : t('main.title')}</h1>
                  <p className="font-normal text-[#C8C7CE] text-lg">
                    {showFull ? t('main_long.part1') : t('main.part1')}
                  </p>
                  <p className="font-normal text-[#C8C7CE] text-lg">
                    {showFull ? t('main_long.part2') : t('main.part2')}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-[30px]">
                {authorized ? (
                  <button
                    className="flex justify-center rounded-full bg-orange px-2 py-5 text-[24px] font-medium transition-all hover:bg-[#ff9330] active:scale-[97%] active:bg-[#b75800]"
                    onClick={async () => {
                      const auth = await isAuthorized();
                      if (auth) navigate('/new-edit');
                      setAuthorized(auth);
                    }}
                  >
                    {t('launch')}
                  </button>
                ) : (
                  <>
                    {/* {isIpTrial && !Cookies.get('trial_expired') && (
                      <IpLoginButton captchaToken={captchaToken} />
                    )} */}
                    <div className="grid grid-cols-2 w-full  items-center gap-2">
                      <GoogleOAuthProvider clientId="690022601515-rtvim3jb2h976i2us0gf5o9imh2kemrc.apps.googleusercontent.com">
                        <GoogleLoginButton
                          onSuccess={() => {
                            checkAuthorizationButton();
                          }}
                          onFail={(data) => {
                            if (
                              data?.title?.indexOf(
                                'CaptchaToken is invalid'
                              ) !== -1
                            ) {
                              alert(
                                '2. Captcha expired! Please solve the Captcha again'
                              );
                              setCaptchaToken(null);
                            } else {
                              try {
                                alert(
                                  'Unknown error: ' + typeof data === 'object'
                                    ? JSON.stringify(data)
                                    : data
                                );
                              } catch (e) {
                                console.log('error', e);
                              }
                            }
                          }}
                          captchaToken={captchaToken}
                        />
                      </GoogleOAuthProvider>
                      {isAppleLoginVisible && (
                        <AppleLoginButton
                          onSuccess={() => {
                            checkAuthorizationButton();
                          }}
                          onFail={(data) => {
                            console.log(data);
                            if (
                              'error' in data &&
                              data.error === 'popup_closed_by_user'
                            )
                              return;

                            if (
                              data?.title?.indexOf(
                                'CaptchaToken is invalid'
                              ) !== -1
                            ) {
                              alert(
                                '2. Captcha expired! Please solve the Captcha again'
                              );
                              setCaptchaToken(null);
                            } else {
                              try {
                                alert(
                                  'Unknown error: ' + typeof data === 'object'
                                    ? JSON.stringify(data)
                                    : data
                                );
                              } catch (e) {
                                console.log('error', e);
                              }
                            }
                          }}
                          captchaToken={captchaToken}
                        />
                      )}
                      <DiscordLoginButton captchaToken={captchaToken} />
                      <LoginButton
                        captchaToken={captchaToken}
                        setCaptchaToken={setCaptchaToken}
                        checkAuthorizationButton={checkAuthorizationButton}
                      />
                    </div>
                  </>
                )}
                <div className="gap-1 pt-[44px]">
                  <p className="font-normal text-[#C8C7CE] text-lg">
                    Follow us:
                  </p>
                  <div className="flex gap-2">
                    <a
                      target="_blank"
                      href="https://twitter.com/nudify_app"
                      className="hidden rounded-[60px] bg-[#161616] px-[10px] py-[20px] w-full transition-all hover:bg-[#000000] mmd:flex"
                    >
                      <div className="flex">
                        <img
                          src="/icons/twitterLogo.svg"
                          alt="twitter logo"
                          className="h-[42px] w-[42px]"
                        />
                        <span className="text-xl font-normal text-white self-center">
                          @undress_als
                        </span>
                      </div>
                    </a>

                    <a
                      target="_blank"
                      href="https://t.me/nudify_app"
                      className="hidden rounded-[60px] bg-[#161616] px-[10px] py-[20px] w-full transition-all hover:bg-[#039BE5] mmd:flex"
                    >
                      <div className="flex gap-[8px]">
                        <img
                          src="/icons/telegramLogo.svg"
                          alt="telegram logo"
                          className="h-[42px] w-[42px]"
                        />
                        <span className="text-xl font-normal text-white self-center">
                          @nudify_app
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gap-1 pt-[44px] mmd:hidden mb-[96px]">
            <p className="font-normal text-[#C8C7CE] text-lg">Follow us:</p>
            <div className="flex flex-col gap-2">
              <a
                target="_blank"
                href="https://twitter.com/nudify_app"
                className="flex rounded-[60px] bg-[#161616] p-[20px] transition-all hover:bg-[#000000] mmd:hidden"
              >
                <div className="flex gap-[8px]">
                  <img
                    src="/icons/twitterLogo.svg"
                    alt="twitter logo"
                    className="h-[42px] w-[42px]"
                  />

                  <span className="text-[21px] font-normal text-white self-center">
                    @undress_als
                  </span>
                </div>
              </a>
              <a
                target="_blank"
                href="https://t.me/nudify_app"
                className="flex rounded-[60px] bg-[#161616] p-[20px] transition-all hover:bg-[#039BE5] mmd:hidden"
              >
                <div className="flex gap-[8px]">
                  <img
                    src="/icons/telegramLogo.svg"
                    alt="telegram logo"
                    className="h-[42px] w-[42px]"
                  />
                  <span className="text-[21px] font-normal text-white self-center">
                    @nudify_app
                  </span>
                </div>
              </a>
            </div>
          </div>

          <CookiesModal />
          {showFull && (
            <>
              {/* <SliderBlock className="mb-[162px] mmd:mb-[128px]" /> */}
              <Feature className="mb-[96px] mmd:mb-[128px]" />
              <Faq className="mb-[96px]" />
            </>
          )}

          <Footer showFull={showFull} />
        </div>
      </div>
      {/* <FollowModal /> */}
    </HelmetProvider>
  );
};

export default General;
