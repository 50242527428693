import Loader from 'components/common/Loader';
import General from 'components/general/general';
import MessageModal from 'components/modals/Message';
import ModalInstruction from 'components/modals/modal_instruction';
import AuthorizationContext from 'context/authorizationContext';
import Cookies from 'js-cookie';
import React, { useState, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';

import { DiscordOAuth2Callback } from './components/auth/DiscordOAuth2Callback';
import ErrorMessage from './components/errors';
import { GTMProvider } from './context/GTMContext';
import { sendUserRedeemRequest } from './packets/user/UserInfoRequestPacket';
import { store } from './redux/store';

import './i18n/i18n';
import 'styles/globals.css';
import { MAIN_EDITOR_URL } from './utils/text';

import AnimaBanner from 'components/AnimaBanner';

const Afiliate = lazy(() => import('./components/afiliate'));
const Rules = lazy(() => import('./components/rules'));
const Auth = lazy(() => import('./components/auth/auth'));
const Edit = lazy(() => import('./components/edit/edit'));
const Cabinet = lazy(() => import('./components/cabinet'));
const Blog = lazy(() => import('./components/blog'));
const PageNotFound = lazy(() => import('./components/PageNotFound'));
const Partners = lazy(() => import('./components/partners'));
const Plan = lazy(() => import('./components/plan/plan'));
const Privacy = lazy(() => import('./components/privacy'));
const Refund = lazy(() => import('./components/refund'));
const Contact = lazy(() => import('./components/contact'));
const Complaints = lazy(() => import('./components/complaints'));
const Billing = lazy(() => import('./components/Billing'));
const Checkout = lazy(() => import('./components/Checkout'));
const TelegramGeneration = lazy(
  () => import('./components/telegramGeneration')
);
const TermsOfService = lazy(() => import('./components/terms'));
const NewEdit = lazy(() => import('components/newEdit'));
const NewMainPage = lazy(() => import('components/newMainPage/index'));
const NudxAuth = lazy(() => import('components/nudxAuth'));
const PaypalFail = lazy(() => import('components/plan/paypalFail'));
const ArkPaymentRedirector = lazy(
  () => import('components/plan/ArkPaymentRedirector')
);
const Footer = lazy(() => import('components/newMainPage/Footer/index'));

const Layout = ({ children }) => {
  return (
    <>
      {children}
      <div className="bg-[#1A1730] p-[32px]">
        <Footer />
      </div>
    </>
  );
};
const Payment = lazy(() => import('components/payment/Payment'));

export default function App() {
  const [authorized, setAuthorized] = useState(true);
  localStorage.setItem(MAIN_EDITOR_URL, '/new-edit');

  const ReferralActivation = () => {
    let { code } = useParams();
    Cookies.set('referral', code);

    //save all others params in new redirect url
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.delete('code');
    const newSearch = searchParams.toString();
    return <Navigate to={`/?${newSearch}`} />;
  };

  const LoginAsUser = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    Cookies.set('token', `Bearer ${searchParams.get('token')}`);
    location.replace('/edit');
  };

  const ActivateRedeem = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get('code');
    sendUserRedeemRequest(code).then(() => {
      location.replace('/edit');
    });
  };

  const CustomPayTypes = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pay = searchParams.get('pay');
    sessionStorage.setItem('payTypeFromSession', pay);
    location.replace('/plan');
  };

  const Instruction = () => {
    const navigate = useNavigate();
    return (
      <ModalInstruction
        showInstructionModal={true}
        instructionModalFunction={() => {
          navigate('/new-edit');
        }}
      />
    );
  };

  const previousPath = localStorage.getItem('previousPath') || '/new-edit';

  // const IsAgreePopupDone = () =>{
  //   return sessionStorage.getItem('Modal18EditAgree') === 'true';
  // }

  return (
    <Provider store={store}>
      <AuthorizationContext.Provider value={{ authorized, setAuthorized }}>
        <GTMProvider>
          <Loader />
          <ErrorMessage />
          <MessageModal />
          <Router>
            {/*{IsAgreePopupDone() && (<AnimaBanner />)}*/}
            <Suspense fallback={<Loader forceLoading />}>
              <Routes>
                <Route
                  path="/"
                  element={<General />}
                />
                <Route
                  path="/undress-ai/"
                  element={<General showFull />}
                />
                <Route
                  path="/auth"
                  element={<Auth />}
                />

                <Route
                  path="/affiliate"
                  element={<Afiliate />}
                />

                <Route
                  path="/ark/payment"
                  element={<ArkPaymentRedirector />}
                />

                <Route
                  path="/nudx"
                  element={<NudxAuth />}
                />
                <Route
                  path="/new-design"
                  element={<NewMainPage />}
                />
                <Route
                  path="/instruction"
                  element={<Instruction />}
                />
                <Route
                  path="/custom"
                  element={<CustomPayTypes />}
                />
                <Route
                  path="/contact"
                  element={<Contact />}
                />
                <Route
                  path="/complaints"
                  element={<Complaints />}
                />
                <Route
                  path="/billing"
                  element={<Billing />}
                />
                <Route
                  path="/checkout"
                  element={<Checkout />}
                />
                <Route
                  path="/undress-ai/auth"
                  element={<Auth showFull />}
                />
                <Route
                  path="/rules"
                  element={<Rules />}
                />
                <Route
                  path="/redeem"
                  element={<ActivateRedeem />}
                />
                <Route
                  path="/edit"
                  element={<Edit />}
                />
                <Route
                  path="/plan"
                  element={<Plan />}
                />
                <Route
                  path="/payment"
                  element={<Payment />}
                />
                <Route
                  path="/paypal/failed/:order"
                  element={<PaypalFail />}
                />
                <Route
                  path="/privacy"
                  element={<Privacy />}
                />
                <Route
                  path="/terms"
                  element={<TermsOfService />}
                />
                <Route
                  path="/refund"
                  element={<Refund />}
                />
                <Route
                  path="/api/oauth2/discord/callback"
                  element={<DiscordOAuth2Callback />}
                />
                <Route
                  path="/login_as_user"
                  element={<LoginAsUser />}
                />
                <Route
                  path="/ref/:code"
                  element={<ReferralActivation />}
                />
                <Route
                  path="/telegram_undress"
                  element={<TelegramGeneration />}
                />
                <Route
                  path="/cabinet"
                  element={<Cabinet />}
                />
                <Route
                  path="/partners"
                  element={<Partners />}
                />
                <Route
                  path="/blog/:blob"
                  element={<Blog />}
                />
                <Route
                  path="/new-edit"
                  element={
                    <Layout>
                      <NewEdit />
                    </Layout>
                  }
                />
                <Route
                  path="/404"
                  element={<PageNotFound />}
                />
                <Route
                  path="/redirect"
                  element={<Navigate to={previousPath} />}
                />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to="/404"
                      replace
                    />
                  }
                />
              </Routes>
            </Suspense>
          </Router>
        </GTMProvider>
      </AuthorizationContext.Provider>
    </Provider>
  );
}
