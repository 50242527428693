import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { metricChooseAuth } from '../../packets/user/UserInfoRequestPacket';

const DISCORD_AUTH_URL =
  'https://discord.com/api/oauth2/authorize?client_id=1176120686260015125&redirect_uri=https%3A%2F%2Fnudify.online%2Fapi%2Foauth2%2Fdiscord%2Fcallback&response_type=code&scope=identify%20email';

export default function DiscordLoginButton({
  captchaToken,
  newVariant = false,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  if (newVariant) {
    return (
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          metricChooseAuth('discord');
          window.location.href = DISCORD_AUTH_URL + '&state=' + captchaToken;
        }}
        className="flex items-center justify-center p-[6px] rounded-[14px] h-[56px] w-full bg-[#5865F2] cursor-pointer hover:bg-[#7B66FF]"
      >
        <img
          className="w-[26px] h-[20px]"
          src="/icons/discordLogo.svg"
          alt="discord logo"
        />
      </button>
    );
  }

  return (
    <>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          metricChooseAuth('discord');
          window.location.href = DISCORD_AUTH_URL + '&state=' + captchaToken;
        }}
        className="mmd:hidden flex justify-center items-center h-[48px] w-full bg-[#5865F2] rounded-[24px] p-[6px] self-center"
      >
        <img
          className="h-[24px] w-[24px]"
          src="/icons/discordLogo.svg"
          alt="discord logo"
        />
      </button>
      <button
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="mmd:flex hidden items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#5865F2] h-[88px] w-full"
        onClick={() => {
          metricChooseAuth('discord');
          window.location.href = DISCORD_AUTH_URL + '&state=' + captchaToken;
        }}
      >
        <div className="flex items-center justify-center gap-[16px]">
          <div className="flex items-center justify-center h-[48px] w-[48px] bg-[#5865F2] rounded-full">
            <img
              className="w-[26px] h-[20px]"
              src="/icons/discordLogo.svg"
              alt="discord logo"
            />
          </div>
          <div className="hidden flex flex-col mmd:flex">
            <p className="text-[12px] text-[#FFFFFF80]">{t('login_with')}</p>
            <p className="text-[16px] font-semibold">Discord</p>
          </div>
        </div>

        <img
          src="/icons/keyIcon.svg"
          alt="key icon"
          className="hidden mmd:block h-[24px] w-[24px]"
        />
      </button>
    </>
  );
}
