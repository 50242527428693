import Cookies from 'js-cookie';
import React, { useState, useContext, useMemo } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';

import {
  GTM_EVENTS,
  REGISTRATION_TYPES,
  AUTHORIZATION,
} from '../../constants/text';
import { GTMContext } from '../../context/GTMContext';
import { sendLoginRequestV2 } from '../../packets/auth/LoginRequest';
import { metricChooseAuth } from '../../packets/user/UserInfoRequestPacket';

export function AppleLoginButton({
  onSuccess,
  onFail,
  captchaToken,
  newVariant = false,
}) {
  const { dataLayerPush } = useContext(GTMContext);
  const { t } = useTranslation();
  const [appleRedirectUrl] = useState(`https://${location.host}`);
  const appleClientId = useMemo(() => {
    let clientId = 'dev.undressit';
    switch (location.host) {
      case 'nudify.online':
        clientId = 'onlinenudifyauth';
        break;
      case 'undresser.io':
        clientId = 'cyberphoto.web';
        break;
      case 'magicphoto.app':
        clientId = 'neuronet.web';
        break;
      default:
    }
    return clientId;
  }, []);

  const handleSuccess = async (response) => {
    metricChooseAuth(REGISTRATION_TYPES.APPLE);

    console.log(response);

    if (!response.hasOwnProperty(AUTHORIZATION)) {
      onFail('Authorization code for AppleID is not found');
      return;
    }

    const token = response.authorization.code;
    let loginResponse;
    try {
      loginResponse = await sendLoginRequestV2(
        token,
        'APPLE',
        captchaToken || 'null'
      );
    } catch (e) {
      alert(e.toString());
      onFail(e?.response?.data);
      return;
    }

    if (loginResponse.data !== null) {
      Cookies.set('token', loginResponse.data.token);
    }
    if (onSuccess) {
      onSuccess();
    }
    dataLayerPush({
      event: `${
        loginResponse.data.isRegistration
          ? GTM_EVENTS.USER_REGISTERED
          : GTM_EVENTS.USER_AUTHORIZED
      }`,
      event_cat: AUTHORIZATION,
      event_name: 'go',
      registration_type: REGISTRATION_TYPES.APPLE,
      param_success: 'yes',
    });
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <AppleSignin
      authOptions={{
        clientId: appleClientId,
        redirectURI: appleRedirectUrl,
        scope: 'email',
        state: 'origin:web',
        usePopup: true,
      }}
      onSuccess={(response) => handleSuccess(response)}
      onError={(error) => onFail(error)}
      render={(props) => (
        <>
          {newVariant ? (
            <div
              {...props}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className="flex items-center justify-center p-[6px] rounded-[14px] h-[56px] w-full bg-[#E7E6EC] cursor-pointer hover:bg-[#ADABC0]"
            >
              <img
                className="h-[24px] w-[24px]"
                src="/icons/appleLogo.svg"
                alt="apple logo"
              />
            </div>
          ) : (
            <>
              <button
                {...props}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="mmd:hidden flex justify-center items-center h-[48px] w-full bg-[#E7E6EC] rounded-[24px] p-[6px] self-center"
              >
                <img
                  className="h-[24px] w-[24px]"
                  src="/icons/appleLogo.svg"
                  alt="apple logo"
                />
              </button>
              <button
                {...props}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="mmd:flex hidden h-[88px] w-full items-center justify-between gap-[16px] self-stretch rounded-[44px] bg-[#121212] pl-[16px] pr-[24px] text-start transition-all hover:bg-[#FFFFFF]"
              >
                <div className="flex items-center justify-center gap-[16px]">
                  <div className="flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#FFFFFF]">
                    <img
                      className="h-[24px] w-[24px]"
                      src="/icons/appleLogo.svg"
                      alt="apple logo"
                    />
                  </div>
                  <div className="hidden flex flex-col mmd:flex">
                    <p
                      className={`text-[12px] ${
                        isHovered ? 'text-[#20202080]' : 'text-[#FFFFFF80]'
                      }`}
                    >
                      {t('login_with')}
                    </p>
                    <p
                      className={`text-[16px] font-semibold ${
                        isHovered ? 'text-[#000000]' : ''
                      }`}
                    >
                      Apple
                    </p>
                  </div>
                </div>

                <img
                  src="/icons/keyIcon.svg"
                  alt="key icon"
                  className="hidden mmd:block h-[24px] w-[24px]"
                />
              </button>
            </>
          )}
        </>
      )}
    />
  );
}
